<template>
  <div>
    <!-- Header -->
    <div class="header">
      <div class="title">{{$t('track-menu.title')}}</div>
    </div>
    <!-- Content -->
    <div class="content">
      <v-expansion-panels v-model="panel" multiple focusable>
        <!-- graphTracks -->
        <v-expansion-panel>
          <v-expansion-panel-header>{{$t('track-menu.graphsTitle')}}</v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-checkbox
              v-model="allGraphTracksSelected"
              :label="'All'"
              @change="toggleAllTracks('graph')"
              hide-details>
            </v-checkbox>
            <v-divider class="divider"></v-divider>
            <ul>
              <li v-for="(graphTrack, index) in graphTracks" :key="index">
                <v-checkbox
                  :input-value="isTrackDisabled('graph', graphTrack[0])"
                  :label="`${graphTrack[0]}`.startsWith('TRB-') ? `${graphTrack[0]}`.replace('Av1', '') : `${graphTrack[0]}`"
                  @change="toggleTrack('graph', graphTrack[0])"
                  hide-details>
                </v-checkbox>
              </li>
            </ul>
          </v-expansion-panel-content>
        </v-expansion-panel>

        <!-- vcfTracks -->
        <v-expansion-panel v-for="(tracks, group) in groupedVCFTracks" :key="group">
          <v-expansion-panel-header>
            <v-checkbox
              :input-value="isGroupFullyEnabled(group)"
              :label="group"
              @change="toggleGroup(group)"
              hide-details
              class="mr-2 mt-n1"
            ></v-checkbox>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <ul>
              <li v-for="(vcfTrack, index) in tracks" :key="`${group}.${vcfTrack}.${index}`">
                <v-checkbox
                  :input-value="isVCFTrackEnabled(vcfTrack)"
                  :label="vcfTrack.startsWith('TRB-') ? vcfTrack.replace('Av1', '') : vcfTrack"
                  @change="toggleVCFTrack(vcfTrack)"
                  hide-details
                >
                  <template v-slot:label>
                    <div class="d-flex align-center">
                      {{ vcfTrack }}
                      <div v-if="isTrackSum(vcfTrack)" class="green-circle ml-2"></div>
                    </div>
                  </template>
                </v-checkbox>
              </li>
            </ul>
          </v-expansion-panel-content>
        </v-expansion-panel>

        <!-- readTracks -->
        <v-expansion-panel v-if="readTracks">
          <v-expansion-panel-header><div class="panel-title">{{$t('track-menu.readsTitle')}}</div></v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-checkbox
              v-model="allReadTracksSelected"
              :label="'All'"
              @change="toggleAllTracks('read')"
              hide-details>
            </v-checkbox>
            <v-divider class="divider"></v-divider>
            <ul>
              <li v-for="(readTrack, index) in readTracks" :key="index">
                <v-checkbox
                  :input-value="isTrackDisabled('read', readTrack.readset_name)"
                  :label="`${readTrack.readset_name}`"
                  @change="toggleTrack('read', readTrack.readset_name)"
                  hide-details
                ></v-checkbox>
              </li>
            </ul>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </div>
  </div>
</template>

<script lang="ts">
import { Vue, Component, Watch } from 'vue-property-decorator'

// TrackMenu allows the user to select which tracks (paths or reads) to display in Pantoview
@Component
export default class TrackMenu extends Vue {
  panel = []

  // ---------------------------
  // GraphTracks
  // ---------------------------

  get allGraphTracksSelected () {
    return this.$store.state.pantoStore.allGraphTracksSelected
  }

  set allGraphTracksSelected (value: boolean) {
    this.$store.commit('pantoStore/setAllGraphTracksSelected', value)
  }

  get graphTracks () {
    return this.$store.state.chunkStore.rawGraphTracks
  }

  // A new string (graph name) is pushed/removed every time the user disables/enables a graph
  get disabledGraphTracks () {
    return this.$store.state.pantoStore.disabledGraphTracks
  }

  set disabledGraphTracks (graphTracks: string[]) {
    this.$store.commit('pantoStore/setDisabledGraphTracks', graphTracks)
  }

  @Watch('graphTracks')
  onGraphTracksChanged (val: any) {
    if (val.entries().next().value !== undefined) {
      this.allGraphTracksSelected = this.areAllTracksSelected('graph')
    }
  }

  // ---------------------------
  // VCFTracks
  // ---------------------------

  vcfTracksVisibility = 'hide' // Set default to 'hide'

  get allVCFTracksSelected () {
    return this.$store.state.pantoStore.allVCFTracksSelected
  }

  set allVCFTracksSelected (value: boolean) {
    this.$store.commit('pantoStore/setAllVCFTracksSelected', value)
  }

  get vcfTracks () {
    return this.$store.state.chunkStore.rawVCFTracks
  }

  get enabledVCFTracks () {
    return this.$store.state.pantoStore.enabledVCFTracks
  }

  set enabledVCFTracks (tracks: string[]) {
    this.$store.commit('pantoStore/setEnabledVCFTracks', tracks)
  }

  get groupedVCFTracks (): { [key: string]: string[] } {
    const grouped: { [key: string]: string[] } = {}
    const sumTracks: string[] = []

    for (const track of this.vcfTracks) {
      const trackInfo = this.$store.state.chunkStore.vcfTracks.get(track)
      if (trackInfo) {
        if (trackInfo.sum && !sumTracks.includes(track)) {
          // Collect all sum tracks
          sumTracks.push(track)
          continue
        }

        // Process all tracks (including sum tracks) for their original groups
        if (trackInfo.group) {
          // if (!(trackInfo.group in this.$store.state.chunkStore.vcfGroupNames)) {
          //   continue
          // }
          const groups: string[] = trackInfo.group.split(',')
          groups.filter((g) => this.$store.state.chunkStore.vcfGroupNames.includes(g)).forEach(group => {
            if (!grouped[group]) {
              grouped[group] = []
            }
            if (!grouped[group].includes(track)) {
              grouped[group].push(track)
            }
          })
        } else {
          // Handle tracks without a group
          if (!grouped.Ungrouped) {
            grouped.Ungrouped = []
          }
          if (!grouped.Ungrouped.includes(track)) {
            grouped.Ungrouped.push(track)
          }
        }
      }
    }

    // Sort tracks in each group lexicographically by its key
    for (const group in grouped) {
      grouped[group].sort((a, b) => a.localeCompare(b))
    }

    // Add sum tracks to a separate group if there are any
    if (sumTracks.length > 0) {
      grouped['Summarized Tracks'] = sumTracks
    }

    return grouped
  }

  @Watch('enabledVCFTracks')
  onVCFTracksChanged (val: any) {
    if (val.entries().next().value !== undefined) {
      this.allVCFTracksSelected = this.areAllVCFTracksSelected()
    }
  }

  // ---------------------------
  // ReadTracks
  // ---------------------------

  get allReadTracksSelected () {
    return this.$store.state.pantoStore.allReadTracksSelected
  }

  set allReadTracksSelected (value: boolean) {
    this.$store.commit('pantoStore/setAllReadTracksSelected', value)
  }

  get readTracks () {
    return this.$store.state.chunkStore.readTracks[this.$store.state.chunkStore.currentChunk]
  }

  get disabledReadTracks () {
    return this.$store.state.pantoStore.disabledReadTracks
  }

  set disabledReadTracks (readTracks: string[]) {
    this.$store.commit('pantoStore/setDisabledReadTracks', readTracks)
  }

  @Watch('readTracks')
  onReadTracksChanged (val: any) {
    if (val.entries().next().value !== undefined) {
      this.allReadTracksSelected = this.areAllTracksSelected('read')
    }
  }

  // ---------------------------

  isTrackSum (track: string): boolean {
    const trackInfo = this.$store.state.chunkStore.vcfTracks.get(track)
    return trackInfo && trackInfo.sum === true
  }

  isGroupFullyEnabled (group: string | number): boolean {
    const groupTracks = this.groupedVCFTracks[group] || []
    return groupTracks.every(track => this.isVCFTrackEnabled(track))
  }

  toggleGroup (group: string | number) {
    const groupTracks = this.groupedVCFTracks[group] || []
    const shouldEnable = !this.isGroupFullyEnabled(group)

    const updatedEnabledTracks = shouldEnable
      ? [...new Set([...this.enabledVCFTracks, ...groupTracks])]
      : this.enabledVCFTracks.filter(track => !groupTracks.includes(track))

    this.enabledVCFTracks = updatedEnabledTracks
  }

  getDisabledTracks (type: string) {
    if (type === 'graph') return this.disabledGraphTracks
    else return this.disabledReadTracks
  }

  getAllTracksSelected (type: string) {
    if (type === 'graph') return this.allGraphTracksSelected
    else if (type === 'vcf') return this.allVCFTracksSelected
    else return this.allReadTracksSelected
  }

  setAllTracksSelected (type: string, value: boolean) {
    if (type === 'graph') this.allGraphTracksSelected = value
    else if (type === 'vcf') this.allVCFTracksSelected = value
    else this.allReadTracksSelected = value
  }

  getTracks (type: string) {
    if (type === 'graph') return this.graphTracks
    else if (type === 'vcf') return this.vcfTracks
    else if (this.readTracks !== undefined) {
      // If readTracks are found in the dataset, create a map with the readset_name as key
      // otherwise, return the graphTracks, which are always present
      const readTracks = new Map()
      for (const track of this.readTracks) {
        readTracks.set(track.readset_name, track)
      }
      return readTracks
    }
  }

  isTrackDisabled (type: string, name: string): boolean {
    return !(this.getDisabledTracks(type).indexOf(name) > -1)
  }

  areAllTracksSelected (type: string): boolean {
    const disabledTracks = this.getDisabledTracks(type)
    const currentTracks = this.getTracks(type)
    for (const [key] of currentTracks) {
      if (disabledTracks.indexOf(key) > -1) {
        return false
      }
    }
    return true
  }

  toggleAllTracks (type: string) {
    const disabledTracks = this.getDisabledTracks(type)
    const currentTracks = this.getTracks(type)
    for (const [key] of currentTracks) {
      const index = disabledTracks.indexOf(key)
      if (this.getAllTracksSelected(type)) {
        disabledTracks.splice(index, 1)
      } else {
        if (index === -1) {
          disabledTracks.push(key)
        }
      }
    }
  }

  toggleTrack (type: string, key: string) {
    const disabledTracks = this.getDisabledTracks(type)
    const index = disabledTracks.indexOf(key)
    if (index > -1) {
      disabledTracks.splice(index, 1)
    } else {
      disabledTracks.push(key)
    }
    this.setAllTracksSelected(type, this.areAllTracksSelected(type))
  }

  // ---------------------------
  // VCFTracks
  // ---------------------------
  isVCFTrackEnabled (name: string): boolean {
    return this.enabledVCFTracks.indexOf(name) > -1
  }

  areAllVCFTracksSelected (): boolean {
    for (const key of this.vcfTracks) {
      if (this.enabledVCFTracks.indexOf(key) === -1) {
        return false
      }
    }
    return true
  }

  toggleVCFTrack (track: string) {
    const enabledVCFTracks = this.enabledVCFTracks
    const index = enabledVCFTracks.indexOf(track)
    if (index > -1) {
      enabledVCFTracks.splice(index, 1)
    } else {
      enabledVCFTracks.push(track)
    }
    this.enabledVCFTracks = [...enabledVCFTracks]
    this.allVCFTracksSelected = this.areAllVCFTracksSelected()
  }

  handleVCFTracksVisibilityChange (value: string) {
    if (value === 'show') {
      this.enabledVCFTracks = [...new Set(this.vcfTracks)] as string[]
    } else {
      this.enabledVCFTracks = []
    }
  }
}
</script>

<style scoped>
.green-circle {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #26d953;
}
</style>
