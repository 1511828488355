import Papa from 'papaparse'
import { FileValidationResult } from '@/types/Types'
import { ApiQueryService } from '@/services/ApiQueryService'

export class CSVValidator {
  private static readonly EXPECTED_VCF_HEADER = ['group', 'reference', 'vcf']
  private static readonly EXPECTED_GRAPH_HEADER = ['sample', 'graph', 'fasta', 'gff']
  private static readonly EXPECTED_GENO_HEADER = ['group', 'sample', 'fastq_1', 'fastq_2']
  static fileList: string[]

  // VCF pipeline

  public static validateVCFSamplesheet (file: File): Promise<FileValidationResult> {
    return new Promise<FileValidationResult>((resolve, reject) => {
      let dataFolder = ''
      Papa.parse(file as any, {
        complete: (results: Papa.ParseResult<string[]>) => {
          const errors: string[] = []
          const promises: Promise<string | void>[] = []

          if (results.data.length < 2) { // At least header + one data row
            errors.push('The file must contain a header row and at least one data row')
          } else {
            // Validate header
            const header = results.data[0]
            if (!this.validateHeader(header, errors, this.EXPECTED_VCF_HEADER)) {
              resolve({ content: null, dataFolder: null, isValid: false, errors })
              this.fileList = []
              return
            }

            // Validate data rows (not the first one (is the header), not the last one (is empty))
            results.data.slice(1).slice(0, -1).forEach((row, index) => {
              promises.push(this.validateVCFRow(row, index + 1, errors)
                .then((folder: string) => {
                  if (dataFolder === '') {
                    dataFolder = folder
                  }
                  if (folder !== dataFolder) {
                    errors.push('All VCF files must be in the same folder')
                  }
                }))
            })
          }

          Promise.all(promises).then(() => {
            this.fileList = []
            resolve({
              content: results.data.join('\n'),
              dataFolder,
              isValid: errors.length === 0,
              errors
            })
          })
        },
        error: (error: Papa.ParseError) => {
          this.fileList = []
          reject(new Error(`Error parsing CSV file: ${error.message}`))
        }
      } as Papa.ParseConfig<string[]>)
    })
  }

  private static validateHeader (header: string[], errors: string[], expectedHeader: string[]): boolean {
    if (header.length !== expectedHeader.length) {
      errors.push(`Header should have ${expectedHeader.length} columns, found ${header.length}`)
      return false
    }
    if (!header.every((col, index) => col.toLowerCase() === expectedHeader[index])) {
      errors.push(`Invalid header. Expected: ${expectedHeader.join(', ')}`)
      return false
    }
    return true
  }

  private static validateVCFRow (row: string[], rowIndex: number, errors: string[]) {
    return new Promise<string>((resolve) => {
      console.log('row', row)
      if (row.length !== this.EXPECTED_VCF_HEADER.length) {
        errors.push(`Row ${rowIndex}: Expected ${this.EXPECTED_VCF_HEADER.length} columns, found ${row.length}`)
        resolve('')
      }

      if (row.some(field => field.trim() === '')) {
        errors.push(`Row ${rowIndex}: All fields must be non-empty`)
        resolve('')
      }

      const file = row[2].split('/').pop() as string
      const folder = row[2].substring(0, row[2].lastIndexOf('/'))
      const relativeFolder = folder.split('/').slice(3).join('/')
      const s3Regex = /^s3:\/\/.+\.vcf(\.gz)?$/i
      const s3Bucket = row[2].split('/').slice(2, 3).join()
      if (!s3Regex.test(row[2])) {
        errors.push(`Row ${rowIndex}: Invalid S3 path for VCF file (${row[2]}). Must start with 's3://' and end with either '.vcf' or 'vcf.gz'`)
        resolve('')
      } else if (s3Bucket !== process.env.VUE_APP_BUCKET_NAME) {
        errors.push(`Row ${rowIndex}: Invalid S3 bucket name. Must be identical to ${process.env.VUE_APP_BUCKET_NAME})`)
        resolve('')
      } else {
        // Check if file exists on s3
        if (!this.fileList || this.fileList.length === 0) {
          // Fetch file list from S3 for the first time
          ApiQueryService.listFiles(relativeFolder, false)
            .then((response) => {
              console.log('s3 folder:', response)
              this.fileList = response.files

              if (!this.fileList.includes(file)) {
                errors.push(`Row ${rowIndex}: File not found on S3: ${row[2]}`)
                resolve('')
              } else {
                resolve(folder)
              }
            })
            .catch((error) => {
              errors.push(`Error checking file on S3: ${error}`)
              resolve('')
            })
        } else {
          if (!this.fileList.includes(file)) {
            errors.push(`Row ${rowIndex}: File not found on S3: ${row[2]}`)
            resolve('')
          } else {
            resolve(folder)
          }
        }
      }
    })
  }

  // Genotyping pipeline

  public static validateGenoSamplesheet (file: File): Promise<FileValidationResult> {
    return new Promise<FileValidationResult>((resolve, reject) => {
      Papa.parse(file as any, {
        complete: (results: Papa.ParseResult<string[]>) => {
          const errors: string[] = []
          const promises: Promise<string | void>[] = []

          if (results.data.length < 2) { // At least header + one data row
            errors.push('The file must contain a header row and at least one data row')
          } else {
            // Validate header
            const header = results.data[0]
            if (!this.validateHeader(header, errors, this.EXPECTED_GENO_HEADER)) {
              resolve({ content: null, dataFolder: null, isValid: false, errors })
              return
            }

            // Validate data rows (not the first one (is the header), not the last one (is empty))
            // console.log('samplesheet data', results.data)
            results.data.slice(1).slice(0, -1).forEach((row, index) => {
              promises.push(this.validateGenoRow(row, index + 1, errors))
            })
          }

          Promise.all(promises).then(() => {
            this.fileList = []
            resolve({
              content: results.data.join('\n'),
              dataFolder: '',
              isValid: errors.length === 0,
              errors
            })
          })
        },
        error: (error: Papa.ParseError) => {
          reject(new Error(`Error parsing CSV file: ${error.message}`))
        }
      } as Papa.ParseConfig<string[]>)
    })
  }

  private static validateGenoRow (row: string[], rowIndex: number, errors: string[]) {
    return new Promise<void>((resolve) => {
      // console.log('row', row)
      if (row.length !== this.EXPECTED_GENO_HEADER.length) {
        errors.push(`Row ${rowIndex}: Expected ${this.EXPECTED_GENO_HEADER.length} columns, found ${row.length}`)
        resolve()
      }

      if (row.slice(0, 3).some(field => field.trim() === '')) {
        errors.push(`Row ${rowIndex}: Columns 0 to 2 must be non-empty`)
        resolve()
      }

      const promises: Promise<void>[] = []
      // Check for fastq read files (required)
      for (const colIdx of [2, 3]) {
        const faFile = row[colIdx].split('/').pop() as string
        const faFolder = row[colIdx].substring(0, row[colIdx].lastIndexOf('/'))
        const numberSlashes = faFolder.split('/').length
        if (numberSlashes < 4) {
          errors.push(`Files must be in a subfolder of the project folder ${process.env.VUE_APP_PROJECT_PATH}`)
          resolve()
        }
        let faRelativeFolder = faFolder.split('/').slice(3, numberSlashes).join('/')
        // console.log(numberSlashes, 'faRelativeFolder', faRelativeFolder, 'faFolder', faFolder, 'faFile', faFile)
        if (!faRelativeFolder.endsWith('/')) {
          faRelativeFolder += '/'
        }
        const faS3Regex = /^s3:\/\/.+\.(fq|fastq)(\.gz)?$/i
        const s3Bucket = row[2].split('/').slice(2, 3).join()
        if (!faS3Regex.test(row[colIdx])) {
          errors.push(`Row ${rowIndex}: Invalid S3 path for fastq read file (${row[colIdx]}). Must start with 's3://' and end with either '.fq', '.fastq', 'fq.gz', or 'fastq.gz'`)
          resolve()
        } else if (s3Bucket !== process.env.VUE_APP_BUCKET_NAME) {
          errors.push(`Row ${rowIndex}: Invalid S3 bucket name. Must be identical to ${process.env.VUE_APP_BUCKET_NAME})`)
          resolve()
        } else {
          promises.push(ApiQueryService.listFiles(faRelativeFolder, false)
            .then((response) => {
              const fileList = response.files
              // console.log(faRelativeFolder, response, 'fileList', fileList, faFile)

              if (!fileList.includes(faFile)) {
                errors.push(`Row ${rowIndex}: File not found on S3: ${row[colIdx]}`)
                resolve()
              }
            })
            .catch((error) => {
              errors.push(`Error checking file on S3: ${error}`)
              resolve()
            }))
        }
      }

      Promise.all(promises).then(() => {
        resolve()
      })
    })
  }

  // Graph Update Pipeline

  public static validateGraphSamplesheet (file: File): Promise<FileValidationResult> {
    return new Promise<FileValidationResult>((resolve, reject) => {
      Papa.parse(file as any, {
        complete: (results: Papa.ParseResult<string[]>) => {
          const errors: string[] = []
          const promises: Promise<string | void>[] = []

          if (results.data.length < 2) { // At least header + one data row
            errors.push('The file must contain a header row and at least one data row')
          } else {
            // Validate header
            const header = results.data[0]
            if (!this.validateHeader(header, errors, this.EXPECTED_GRAPH_HEADER)) {
              resolve({ content: null, dataFolder: null, isValid: false, errors })
              return
            }

            // Validate data rows (not the first one (is the header), not the last one (is empty))
            console.log('samplesheet data', results.data)
            results.data.slice(1).slice(0, -1).forEach((row, index) => {
              promises.push(this.validateGraphRow(row, index + 1, errors))
            })
          }

          Promise.all(promises).then(() => {
            this.fileList = []
            resolve({
              content: results.data.join('\n'),
              dataFolder: '',
              isValid: errors.length === 0,
              errors
            })
          })
        },
        error: (error: Papa.ParseError) => {
          reject(new Error(`Error parsing CSV file: ${error.message}`))
        }
      } as Papa.ParseConfig<string[]>)
    })
  }

  private static validateGraphRow (row: string[], rowIndex: number, errors: string[]) {
    return new Promise<void>((resolve) => {
      console.log('row', row)
      if (row.length !== this.EXPECTED_GRAPH_HEADER.length) {
        errors.push(`Row ${rowIndex}: Expected ${this.EXPECTED_GRAPH_HEADER.length} columns, found ${row.length}`)
        resolve()
      }

      if (row.slice(0, 3).some(field => field.trim() === '')) {
        errors.push(`Row ${rowIndex}: Columns 0 to 2 must be non-empty`)
        resolve()
      }

      // Check for genome fasta file (required)
      const faFile = row[2].split('/').pop() as string
      const faFolder = row[2].substring(0, row[2].lastIndexOf('/'))
      const faRelativeFolder = faFolder.split('/').slice(3).join('/')
      const faS3Regex = /^s3:\/\/.+\.f(a(sta)?|na)(\.gz)?$/i
      const s3Bucket = row[2].split('/').slice(2, 3).join()
      const promises: Promise<void>[] = []
      if (!faS3Regex.test(row[2])) {
        errors.push(`Row ${rowIndex}: Invalid S3 path for genome fasta file (${row[2]}). Must start with 's3://' and end with either '.fa', 'fna', 'fasta', '.fa.gz', 'fna.gz', or 'fasta.gz'`)
        resolve()
      } else if (s3Bucket !== process.env.VUE_APP_BUCKET_NAME) {
        errors.push(`Row ${rowIndex}: Invalid S3 bucket name. Must be identical to ${process.env.VUE_APP_BUCKET_NAME})`)
        resolve()
      } else {
        promises.push(ApiQueryService.listFiles(faRelativeFolder, false)
          .then((response) => {
            const fileList = response.files

            if (!fileList.includes(faFile)) {
              errors.push(`Row ${rowIndex}: File not found on S3: ${row[2]}`)
              resolve()
            }
          })
          .catch((error) => {
            errors.push(`Error checking file on S3: ${error}`)
            resolve()
          }))
      }

      // Check for gff file (optional)
      if (row[3] !== '') {
        const gffFile = row[3].split('/').pop() as string
        const gffFolder = row[3].substring(0, row[2].lastIndexOf('/'))
        const gffRelativeFolder = gffFolder.split('/').slice(3).join('/')
        const gffS3Regex = /^s3:\/\/.+\.gff(3)?$/i
        const s3Bucket = row[2].split('/').slice(2, 3).join()
        if (!gffS3Regex.test(row[3])) {
          errors.push(`Row ${rowIndex}: Invalid S3 path for gff file (${row[3]}). Must start with 's3://' and end with either '.gff' or 'gff3'`)
          resolve()
        } else if (s3Bucket !== process.env.VUE_APP_BUCKET_NAME) {
          errors.push(`Row ${rowIndex}: Invalid S3 bucket name. Must be identical to ${process.env.VUE_APP_BUCKET_NAME})`)
          resolve()
        } else {
          promises.push(ApiQueryService.listFiles(gffRelativeFolder, false)
            .then((response) => {
              console.log('s3 folder:', response)
              const fileList = response.files

              if (!fileList.includes(gffFile)) {
                errors.push(`Row ${rowIndex}: File not found on S3: ${row[3]}`)
                resolve()
              }
            })
            .catch((error) => {
              errors.push(`Error checking file on S3: ${error}`)
              resolve()
            }))
        }
      }

      Promise.all(promises).then(() => {
        resolve()
      })
    })
  }
}
